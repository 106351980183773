
  import { mapState } from 'vuex'
  
  export default {
    data() {
      return {
        style: null,
        itemsUnavailable: false
      }
    },
    async mounted() {
      try {
        const walletOffers = await this.$api.getWalletOffers()
        this.$store.commit('setWalletOffers', walletOffers.rewards)
      } catch (error) {
        console.error(error)
      }

      // Apply promo code if it's set
      if (this.offerToApply) {
        const valid = await this.$api.addCoupon(this.offerToApply.cred.toUpperCase())
        if (valid) {
          this.$store.commit('setOfferToApply', null)
        }
      }

      // Check if any products are unavailable
      let productIds = []
      let unavailableProducts = []
      this.order.products.forEach(product => {
        productIds.push(product.product_id)
      })
      this.menu.categories.forEach(category => {
        category.products.forEach(product => {
          if (productIds.includes(product.id)) {
            if (!product.availability.now && this.order.time.mode == 'asap') {
              this.itemsUnavailable = true
            }
          }
        })
      })
    },
    mounted() {
      this.setStyle()
      window.addEventListener('scroll',this.setStyle)
      window.addEventListener('resize',this.setStyle)

      if (this.order && this.order.products && this.order.totals) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'view_cart',
          'items': this.order.products,
          'value': this.order.totals.total,
          'currency': 'USD'
        })
      }
      if (this.mobile) {
        document.querySelector('body').style.overflowY = 'hidden'
      }

      this.getOffers()
    },
    destroyed() {
      window.removeEventListener('scroll',this.setStyle)
      window.removeEventListener('resize',this.setStyle)
      // Remove style tag from body or set to overflow auto
      const body = document.querySelector('body')
      const styleText = body.textContent.trim()
      const cssRules = styleText.split(';')
      if (cssRules.length === 1 && cssRules[0].trim().startsWith('overflow-y')) {
        body.removeAttribute('style')
      } else {
        body.style.overflowY = 'auto'
      }
    },
    computed: {
      ...mapState(['headerHeight','order','walletOffers','user', 'channel', 'handoffToggle']),
      mobile() {
        return (this.$mq == 'sm' || this.$mq == 'md')
      }
    },
    methods: {
      setStyle() {
        // const position = window.scrollY || window.pageYOffset
        // if (position >= this.headerHeight.replace('px','')) {
        //   this.style = 'top:0px;position:fixed'
        // } else {
          this.style = 'top:'+this.headerHeight
        // }
      },
      checkout() {
        if (this.$route.path.includes('checkout')) {
          this.$store.commit('setShowCart', false)
        } else {
          this.$router.push('/order/checkout')
        }
      },
      async getOffers() {
        if (this.user) {
          try {
            const walletOffers = await this.$api.getWalletOffers()
            let offers = []
            walletOffers.rewards.forEach(offer => {
              if (offer.status == 'incomplete') {
                offers.push(offer)
              }
            })
            this.$store.commit('setWalletOffers', offers)
          } catch (error) {
            console.error(error)
          }
        }
      }
    },
    watch: {
      '$route.path'(e) {
        this.$store.commit('setShowCart', false)
      }
    }
  }
