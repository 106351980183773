
  import { mapState } from 'vuex'

  export default {
    data() {
      return {
        // upsells: [],
        added: [],
        imgPath: process.env.GW_ENV === 'stage' || process.env.GW_ENV === 'prod' ? 'https://olo-images-live.imgix.net/' : 'https://olo-images-sandbox.imgix.net/',
        defaultImg:
        '//storyblok.pleinaircdn.com/f/110020/1246x1090/32e553da7e/food-pic.png'
      }
    },
    computed: {
      ...mapState(['menu', 'order']),
      // filteredUpsells() {
      //   return this.realUpsells.filter((upsell, i) => i < 2);
      // },
      hardcodedUpsells() {
        const nameOfItemsToUpsell = [
          'Legendary Iced Tea® Sweet', 
          'Legendary Iced Tea® Unsweet', 
          'Football Bo-Berry Biscuit®', 
          'Bo-Tato Rounds®', 
          'Sweet Potato Pie', 
          'Bo* Fries'
        ]

        const upsells = this.menu.categories
          .map((categories) => categories.products)
          .flat()
          .filter((product) => nameOfItemsToUpsell.includes(product.name)) || []

        // Remove items that are already in the cart
        const cartItems = this.order.products.map((product) => product.name)
        const filteredUpsells = upsells.filter((upsell) => !cartItems.includes(upsell.name))

        return filteredUpsells

        // // Select two random items from the upsells
        // const randomUpsells = filteredUpsells.sort(() => 0.5 - Math.random()).slice(0, 2)

        // return randomUpsells
      }
    },
    // async mounted() {
    //   this.upsells = await this.$api.getUpsellsForCart()
    // },
    methods: {
      // async addToCart(item) {
      //   this.cartMsg = 'Adding to bag…'

      //   const order = await this.$api.addToOrder([{
      //     id: item.id,
      //     quantity: 1,
      //     choices: []
      //   }])

      //   if (order) {
      //     window.dataLayer = window.dataLayer || []
      //     window.dataLayer.push({
      //       'event': 'add_to_cart',
      //       'items': [item],
      //       'currency': 'USD'
      //     });

      //     this.added.push(item.sku)
      //     this.cartMsg = 'Added to bag'
      //     this.$store.commit('setErrors', [])
      //     // this.$store.commit('setShowCart', true)

      //     if (process.browser) {
      //       try {
      //         const bag = document.querySelector('.bag')
      //         bag.classList.add('bounce')
      //         setTimeout(function () {
      //           bag.classList.remove('bounce')
      //         }, 700)
      //       } catch (err) {}
      //     }
      //   } else {
      //     // do something with the error
      //   }
      // },
      customize(item) {
        const currentId = this.$route.query.id
        const newId = item.sku

        if (currentId !== newId) {
          const link = {
            path: '/order/product',
            query: {
              id: newId
            }
          };
          this.$router.push(link)

          if (this.$route.name == 'order-product') {
            setTimeout(() => {
              this.$router.go(link)
            },250)
          }
          this.$store.commit('setShowCart', false)
        }
      },
      productImage(item) {
        if (item.images === null) return this.defaultImg
        try {
          const img = item.images.find((n) => {
            return n.groupname === 'marketplace-product'
          }).filename
          return img ? `${this.imgPath}${img}` : this.defaultImg
        } catch (err) {
          console.error(err)
          return this.defaultImg
        }
      }
    }
  }
