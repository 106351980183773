import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=5d08220a&scoped=true&"
import script from "./Cart.vue?vue&type=script&lang=js&"
export * from "./Cart.vue?vue&type=script&lang=js&"
import style0 from "./Cart.vue?vue&type=style&index=0&id=5d08220a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d08220a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/opt/build/repo/components/icons/IconClose.vue').default,IconInfo: require('/opt/build/repo/components/icons/IconInfo.vue').default,CartItem: require('/opt/build/repo/components/order/CartItem.vue').default,CartPromo: require('/opt/build/repo/components/order/CartPromo.vue').default,CartOffers: require('/opt/build/repo/components/order/CartOffers.vue').default,CartUpsells: require('/opt/build/repo/components/order/CartUpsells.vue').default})
