
  import { mapState } from 'vuex'
  
  export default {
    computed: {
      ...mapState(['location','order', 'channel']),
      mobile() {
        return this.$mq == 'sm' || this.$mq == 'md'
      },
      showMobileNav() {
        return (
          (this.$mq == 'sm' || this.$mq == 'md') &&
          (
            this.$route.name !== 'order-product' &&
            this.$route.name !== 'order-checkout' &&
            this.$route.name !== 'order-items' &&
            this.$route.name !== 'account-login'
          )
        )
      }
    },
    methods: {
      setPickup() {
        if (this.order && this.order.handoff == 'dispatch') {
          this.$api.setHandoff('pickup')
        } else {
          this.$router.push('/locations?mode=pickup')
        }
      },
      setDelivery() {
        this.$router.push('/locations?mode=delivery')
      },

      goToCatering() {
        const targetPath = this.$route.path; // Certifique-se de que 'targetPath' está definido corretamente

        if (process.client) { // Garante que o código só roda no lado do cliente
          if (this.channel !== 'catering' && targetPath.includes('/catering')) {
            this.$store.commit('setChannel', 'catering')
            if (window.location.href.includes('netlify')) { // STAGE/PROD/Feature Branch redirect
              window.location.replace('https://catering--bojangles.netlify.app')
            } else if (process.env.NODE_ENV !== 'development') { // PROD redirect
              window.location.replace('https://catering.bojangles.com')
            }
            // NO LOCAL REDIRECT
          } else if (this.channel === 'catering' && targetPath === '/') {
            this.$store.commit('setChannel', 'default')
            if (window.location.href.includes('netlify')) { // STAGE/PROD/Feature Branch redirect
              window.location.replace('https://stage--bojangles.netlify.app')
            } else if (process.env.NODE_ENV !== 'development') { // PROD redirect
              window.location.replace('https://bojangles.com')
            }
            // NO LOCAL REDIRECT
          }
        }
      }
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.showMobileNav) {
            const recaptchaBadge = document.querySelector('.grecaptcha-badge')
            if (recaptchaBadge) {
              recaptchaBadge.style.bottom = '90px'
            }
          } else {
            const recaptchaBadge = document.querySelector('.grecaptcha-badge')
            if (recaptchaBadge) {
              recaptchaBadge.style.bottom = '14px'
            }
          }
        },500)
      })
    },
    watch: {
      showMobileNav(show) {
        if (show) {
          const recaptchaBadge = document.querySelector('.grecaptcha-badge')
          if (recaptchaBadge) {
            recaptchaBadge.style.bottom = '90px'
          }
        } else {
          const recaptchaBadge = document.querySelector('.grecaptcha-badge')
          if (recaptchaBadge) {
            recaptchaBadge.style.bottom = '14px'
          }
        }
      }
    },
  }
