import { render, staticRenderFns } from "./MobileMenu.vue?vue&type=template&id=5b2ab715&scoped=true&"
import script from "./MobileMenu.vue?vue&type=script&lang=js&"
export * from "./MobileMenu.vue?vue&type=script&lang=js&"
import style0 from "./MobileMenu.vue?vue&type=style&index=0&id=5b2ab715&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2ab715",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconUser: require('/opt/build/repo/components/icons/IconUser.vue').default,IconArrowRight: require('/opt/build/repo/components/icons/IconArrowRight.vue').default,IconArrowLeft: require('/opt/build/repo/components/icons/IconArrowLeft.vue').default,IconChevronRight: require('/opt/build/repo/components/icons/IconChevronRight.vue').default,IconMarker: require('/opt/build/repo/components/icons/IconMarker.vue').default,IconChevronDown: require('/opt/build/repo/components/icons/IconChevronDown.vue').default})
